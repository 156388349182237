.registration {
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  padding: 2em;
  input {
    margin: .7em;
  }
  input:nth-child(4) {
    margin-bottom: 3em;
  }
  .hide {
    display: none;
  }
}
