@mixin layout($breakpoint) {
    // XXS doesn't have a min value.
    @if $breakpoint == xxs {

        .block-#{$breakpoint} {
            display: block;
        }

        .inline-block-#{$breakpoint} {
            display: inline-block;
        }

        .inline-#{$breakpoint} {
            display: inline;
        }

        @include breakpoint-max($breakpoint) {
            .block-#{$breakpoint}-only {
                display: block ;
            }

            .inline-block-#{$breakpoint}-only {
                display: inline-block ;
            }

            .inline-#{$breakpoint}-only {
                display: inline ;
            }
        }
    }
    @else {

        @include breakpoint-min($breakpoint) {

            .block-#{$breakpoint} {
                display: block ;
            }

            .inline-block-#{$breakpoint} {
                display: inline-block ;
            }

            .inline-#{$breakpoint} {
                display: inline ;
            }
            // Large doesn't need an "only" since it operates on min width.
            @if $breakpoint != l {
                @include breakpoint-max($breakpoint) {
                    .block-#{$breakpoint}-only {
                        display: block ;
                    }

                    .inline-block-#{$breakpoint}-only {
                        display: inline-block ;
                    }

                    .inline-#{$breakpoint}-only {
                        display: inline ;
                    }
                }
            }
        }
    }
}
