//==
// Creates text align classes for each breakpoint.
//##
@mixin text-align($breakpoint) {
    // XS doesn't have a min value.
    @if $breakpoint == xxs {

        .text-#{$breakpoint}-pull {
            text-align: left;

            [dir="rtl"] & {
                text-align: right;
            }
        }

        .text-#{$breakpoint}-push {
            text-align: right;

            [dir="rtl"] & {
                text-align: left;
            }
        }

        .text-#{$breakpoint}-center {
            text-align: center;
        }

        @include breakpoint-max($breakpoint) {
            .text-#{$breakpoint}-pull-only {
                text-align: left;

                [dir="rtl"] & {
                    text-align: right;
                }
            }

            .text-#{$breakpoint}-push-only {
                text-align: right;

                [dir="rtl"] & {
                    text-align: left;
                }
            }

            .text-#{$breakpoint}-center-only {
                text-align: center;
            }
        }
    }
    @else {

        @include breakpoint-min($breakpoint) {

            .text-#{$breakpoint}-pull {
                text-align: left;

                [dir="rtl"] & {
                    text-align: right;
                }
            }

            .text-#{$breakpoint}-push {
                text-align: right;

                [dir="rtl"] & {
                    text-align: left;
                }
            }

            .text-#{$breakpoint}-center {
                text-align: center;
            }
            // Large doesn't need an "only" since it operates on min width.
            @if $breakpoint != l {
                @include breakpoint-max($breakpoint) {
                    .text-#{$breakpoint}-pull-only {
                        text-align: left;

                        [dir="rtl"] & {
                            text-align: right;
                        }
                    }

                    .text-#{$breakpoint}-push-only {
                        text-align: right;

                        [dir="rtl"] & {
                            text-align: left;
                        }
                    }

                    .text-#{$breakpoint}-center-only {
                        text-align: center;
                    }
                }
            }
        }
    }
}
