@import "../../../../scss/utilities/variables";
@import "../../../../scss/utilities/mixins/breakpoints";

// this replaces the areac covered by the nav when it becomes sticky.
// when the nav becomes sticky it gets a higher z-index which makes the
// rest of the elements move up in.
.phantom-nav {
  height: 70px;
  display: none;
  &.visible {
    display: block;
  }
}
.nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: 'ITC Avant Garde';
  color: $white;
  height: 50px;
  padding: 3em;
  transition: background-color .5s ;
  &__menu {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    padding: 0;
    a {
      margin-right: 90px;
      color: $white;
      text-transform: uppercase;
      font-weight: bold;
      padding-top: 5px;
    }
  }
  &__menu-dropdown {
    margin-right: 90px;
    font-weight: bold;
    button {
      color:$white;
    }
  }
  &--white-background {
    color: $dark-gray;
    a {
      color: $dark-gray;
    }
    button {
      color: $dark-gray;
      &.get-started {
        color: $white;
      }
    }
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    .nav__menu-button, .nav__menu-button::before, .nav__menu-button::after {
      background-color: $dark-gray;
    }
  }
    &--sticky {
    background-color: $white;
    color: $dark-gray;
    a {
      color: $dark-gray;
    }
    button {
      color: $dark-gray;
      &.get-started {
        color: $white;
      }
    }
    .btn--transparent {
      color: $dark-gray;
      border: 1px solid $dark-gray;
    }
    .nav__menu-button, .nav__menu-button::before, .nav__menu-button::after {
      display: block;
      background-color: $dark-gray;
      position: absolute;
      height: 4px;
      width: 30px;
      transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
      border-radius: 2px;
    }
  }
}

.nav__menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#nav__menu-toggle {
  display: none;
}

.nav__menu-button,
.nav__menu-button::before,
.nav__menu-button::after {
  display: block;
  background-color: $white;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.nav__menu-button::before {
  content: "";
  margin-top: -8px;
}

.nav__menu-button::after {
  content: "";
  margin-top: 8px;
}

#nav__menu-toggle:checked
  + .nav__menu-button-container
  .nav__menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#nav__menu-toggle:checked + .nav__menu-button-container .nav__menu-button {
  background: rgba(255, 255, 255, 0);
}

#nav__menu-toggle:checked
  + .nav__menu-button-container
  .nav__menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}
.nav__logo {
  img {
    height: 40px;
  }
}


/* The sticky class is added to the navbar when it reaches its scroll position */
.nav--sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.nav__menu-dropdown {
  position: relative;
  display: inline-block;
  button {
    font-family: 'ITC Avant Garde';
    font-weight: bold;
    font-size: 16px;
    border: none;
    cursor: pointer;
    background: transparent;
    padding-top: 3px;
    &:focus {
      outline: 0;
    }
  }
}

.nav__menu--mobile {
  display: none;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 100px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  a {
    color: $dark-gray;
    padding: 12px;
    margin: 0;
    text-decoration: none;
    display: block;
    &:hover {
      color: $acumen-blue;
    }
  }
}
.show {display: block;}

@include breakpoint-max(s) {
  nav > button {
    display: none;
  }
  .nav__menu-button-container {
    display: flex;
  }
  .nav__menu-dropdown {
    button {
      display: none;
    }
  }

  .nav__menu {
    position: absolute;
    top: 0;
    margin-top: 5em;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    a {
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 0.5em 0;
      width: 100%;
      color: $black;
      background-color: rgba($color: $white, $alpha: 1);
    }
    .nav__menu--mobile {
      display: block;
    }
    .nav__menu--desktop {
      display: none !important;
    }
  }
  #nav__menu-toggle ~ .nav__menu a {
    display: none;
  }
  #nav__menu-toggle:checked ~ .nav__menu a {
    display: flex;
    border: 1px solid $global-border-color;
    height: 2.5em;
    padding: 2.5em;
  }
}
