//==
// Creates float classes for each breakpoint.
//##
@mixin push-pull($breakpoint) {
  // XXS doesn't have a min value.
  @if $breakpoint == xxs {
    .push-#{$breakpoint} {
      float: right;

      [dir="rtl"] & {
        float: left;
      }
    }

    .pull-#{$breakpoint} {
      float: left;

      [dir="rtl"] & {
        float: right;
      }
    }

    @include breakpoint-max($breakpoint) {
      .push-#{$breakpoint}-only {
        float: right;

        [dir="rtl"] & {
          float: left;
        }
      }

      .pull-#{$breakpoint}-only {
        float: left;

        [dir="rtl"] & {
          float: right;
        }
      }
    }
  } @else {
    @include breakpoint-min($breakpoint) {
      .push-#{$breakpoint} {
        float: right;

        [dir="rtl"] & {
          float: left;
        }
      }

      .pull-#{$breakpoint} {
        float: left;

        [dir="rtl"] & {
          float: right;
        }
      }
      // Large doesn't need an "only" since it operates on min width.
      @if $breakpoint != l {
        @include breakpoint-max($breakpoint) {
          .push-#{$breakpoint}-only {
            float: right;

            [dir="rtl"] & {
              float: left;
            }
          }

          .pull-#{$breakpoint}-only {
            float: left;

            [dir="rtl"] & {
              float: right;
            }
          }
        }
      }
    }
  }
}
