@font-face {
  font-family: 'Caecelia LT Std';
  src: local('Caecelia LT Std'), url(./fonts/CaeciliaLTStd-Roman.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ITC Avant Garde';
  src: local('ITC Avant Garde'), url(./fonts/ITCAvantGardeStd-Bold.otf) format('opentype');
  font-weight: bold;
  font-style: normal;
}
