@import '../../../../../scss/utilities/variables';
@import '../../../../../scss/utilities/mixins/breakpoints';

.challenge-nav {
  p {
    display: inline-block;
    margin-left: 1em;
    font-size: 20px;
  }
  img {
    height: 40px;
  }
  &__container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3em;
  }
  &__item {
    cursor: pointer;
  }
  .active {
    border-bottom: 3px solid $acumen-blue;
  }
}

@include breakpoint-max(s) {
  .challenge-nav {
    p {
      margin: 0;
    }
    img {
      margin-right: 15px;
    }
    &__container {
      flex-direction: column;
      margin: 0 auto;
    }
    &__item {
      width: 90%;
      padding: .2em;
      margin: 0 auto;
      margin-bottom: .5em;
    }
  }
}