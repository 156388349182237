@import '../../../../../scss/utilities/variables';

.other-challenge-areas {
  padding: 40px 0;
  color: $white;
  background-color: $violet;
  &__container {
    max-width: 250px;
    margin: 0 auto;
  }
  img {
    height: 30px;
  }
  p {
    display: inline;
    margin: 0;
    margin-left: 1em;
    position: absolute;
    top: .5em;
  }
  &__challenge {
    position: relative;
    margin-bottom: 1em;
    cursor: pointer;
  }
}