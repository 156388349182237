@import '../../../scss/utilities/variables';


// --- setup -------------------------------------------------------------------

$button-border-radius:            $global-radius;
$button-white-border:             1px solid $white;
$button-blue-border:              1px solid $acumen-blue;
$button-font-size:                $global-font-size;
$button-font-family:              "Helvetica Neue", Helvetica, sans-serif;
$button-padding:                  15px 30px 10px 30px;
$button-text-transform:           uppercase;
$button-base-color:               $white;
$button-blue-background:          $acumen-blue;
$button-transparent-background:   transparent;


// --- mixins ------------------------------------------------------------------

@mixin button($name, $background, $border, $color:$button-base-color) {
  .btn--#{$name} {
    font-family: 'ITC Avant Garde';
    background-color: $background;
    border: $border;
    color: $color;
    @include button-hover($background);
  }
}

@mixin button-hover($background) {
  &:hover {
    background-color: lighten($background, 5%);
  }
}

@mixin button-size($name, $font-size) {
  .btn--#{$name} {
    font-size: $font-size;
  }
}

// --- button ------------------------------------------------------------------

.btn {
  color: $button-base-color;
  background-color: $button-blue-background;
  padding: $button-padding;
  text-decoration: none;
  text-transform: $button-text-transform;
  border-radius: $button-border-radius;
  font: $button-font-size $button-font-family;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s;

  @include button-hover($button-blue-background);
}

// --- button: sizes -----------------------------------------------------------

@include button-size('small', 12px);
@include button-size('medium', 16px);
@include button-size('large', 20px);

// --- button: variants --------------------------------------------------------

@include button('blue', $button-blue-background, 'none');
@include button('transparent', $button-transparent-background, $button-white-border);
@include button('transparent-blue', $button-transparent-background, $button-blue-border, $acumen-blue)