@import '../../../scss/utilities/variables';
@import '../../../scss/utilities/mixins/breakpoints';

.card {
  position: relative;
  border: .125em solid gainsboro;
  border-radius: 2px;
  text-align: left;
  height: 100%;
  &__text-area {
    padding: .8em;
    &__text {
      min-height: 100px;
    }
  }
  &__image-container {
    position: relative;
  }
  #overlay {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.2);
    z-index: 2;
    height: 240px;
  }
  &__image {
    width: 100%;
    height: 240px;
    object-fit: cover;
  }
  &__image-text {
    position: absolute;
    top: 8px;
    left: 16px;
    color: $white;
    font-weight: bold;
    font-family: 'ITC Avant Garde';
    font-size: 20px;
    z-index: 3;
  }
  &__subject {
    text-transform: uppercase;
  }
}

@include breakpoint-max(s) {
  .card {
    margin-bottom: 2em;
  }
}