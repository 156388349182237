@import '../../../../../scss/utilities/variables';
@import '../../../../../scss/utilities/mixins/breakpoints';

.challenge-outcomes-section {
    border-top: 1px solid gray;
    text-align: center;
    padding: 60px 0 60px 0;
    img {
        height: 50px;
    }
    p {
        width: 100%;
        font-size: 1.2em;
        padding: 0 1.5em 0 .8em;
    }
    h2 {
        font-size: 2.6em;
        padding-bottom: 60px;
    }
}

@include breakpoint(xxs) {
    .challenge-outcomes-section {
        h2 {
            font-size: 2.3em;
        }
    }
}