@import '../../../../../scss/utilities/variables';
@import '../../../../../scss/utilities/mixins/breakpoints';

  .bottom-padding {
    padding-bottom: 2em;
  }
.half-width {
  width: 50%;
}
.competition-input-form {
  display: inline-block;
  width: 45%;
  p {
    font-size: 16px;
    margin: 0;
  }
  &.first {
    margin-right: 4%;
  }
  &.last {
    width: 100%;
  }
  input {
    width: 100%;
    padding: 12px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #000;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
  }
  input[type=radio] {
    width: 4%;
  }
}
.uni-list {
  h3 {
    text-transform: uppercase;
  }
}

.inactive {
  background-color: lightgray;
}


select {
  border: 1px solid $acumen-blue;
  padding: 12px;
  border-radius: 4px;
  width: 70%;
}
  .full-width {
    width: 100%;
  }

@include breakpoint-max(s) {
  .competition-input-form {
    width: 100%;
  }
}
