@import '../../../scss/utilities/variables';
@import '../../../scss/utilities/mixins/breakpoints';

.partners-section {
  padding: 60px;
  text-align: center;
  .container {
    max-width: 1500px;
    width: 100%;
  }
  .big-ideas {
    padding-top: 2.1em;
  }
  .mit-ideas {
    padding-top: 2.1em;
  }
  .innovation {
    padding-top: 2.5em;
  }
  img {
    max-width: 100%;
  }
}

@include breakpoint-max(s) {
  .partners-section {
    .mit-ideas {
      margin-bottom: 2em;
    }
    .big-ideas {
      padding-top: 0;
      margin-bottom: 2em;
    }
    img {
      margin-bottom: 2em;
    }
  }
}
