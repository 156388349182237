@import '../../../scss/utilities/variables';
@import '../../../scss/utilities/mixins/breakpoints';

.footer {
  background-color: $black;
  color: $white;
  &__bottom {
    display: flex;
    flex-direction: row;
    margin: 0 8em;
    padding: 1em;
  }
  &__right {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    color: $white;
  }
  &__left {
    a {
      color: $white;
      margin: 1em;
    }
  }
}

@include breakpoint-max(s) {
  .footer {
    &__bottom {
      flex-direction: column;
      margin: 0;
    }
    &__right {
      margin: 0 auto;
    }
    &__left {
      margin: 0 auto;
      padding-bottom: 1em;
    }
  }
}
