@import '../../../scss/utilities/variables';
.terms-and-conditions {
  h1 {
    text-transform: uppercase;
    padding-top: 60px;
    font-size: 3em;
  }
  h2 {
    color: $acumen-blue;
  }
  .terms {
    padding-bottom: 60px;
    a {
      color: $acumen-blue;
      &:hover {
        color: $magenta;
      }
    }
  }
}