@import '../../../../../scss/utilities/variables';
@import '../../../../../scss/utilities/mixins/breakpoints';

.challenge-areas {
  text-align: center;
  padding: 60px 0 60px 0;
  h2 {
    font-size: 2.6em;
    padding-bottom: 40px;
  }
  &__subtitle {
    padding-bottom: 60px;
    margin: 0 20em 0 20em;
  }
  .challenges {
    max-width: 100%;
  }
}
.container-fluid {
  padding-right: 15px;
padding-left: 15px;
margin-right: auto;
margin-left: auto;
}
.card__text {
  min-height: 100px;
}

@include breakpoint(xxs) {
  .challenge-areas {
    &__subtitle {
      margin: 0;
    }
  }
}


.col-m-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

@media (min-width: 992px) {
    .col-m-5ths {
        width: 20%;
        float: left;
    }
}
