@import '../../../../scss/utilities/variables';
@import '../../../../scss/utilities/mixins/breakpoints';

.footer-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: 'ITC Avant Garde';
  padding: 1.5em 20em 1.5em 7em;
  border-bottom: 1px solid $global-border-color;
  &__menu {
    a {
      color: $white;
      text-transform: uppercase;
      font-weight: bold;
      margin-right: 40px;
      font-size: 15px;
    }
  }
}

.footer-nav__logo {
  img {
    height: 40px;
  }
}

@include breakpoint-max(s) {
  .footer-nav {
    flex-direction: column;
    align-items: center;
    padding: 3em;
    &__menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      a {
        padding-bottom: 1.8em;
        margin: 0;
        width: 100%;
        text-align: center;
      }
    }
  }
}
