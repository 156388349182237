@import "../../../../../scss/utilities/variables";
@import "../../../../../scss/utilities/mixins/breakpoints";

.hero-section {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("./images/hero-image.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  height: 750px;
}

.hero-section__text {
  padding-top: 5%;
  h1 {
    color: $white;
    text-transform: capitalize;
  }
  p {
    font-size: 1.35em;
    color: $white;
  }
}

@include breakpoint-max(s) {
  .hero-section {
    height: 900px;
  }

  .hero-section__text {
    h1 {
      font-size: 2.6em;
    }
    p {
      margin-top: 0;
    }
  }
}

@include breakpoint-min(m) {
  .hero-section__text {
  padding-top: 1%;
  }
}
