@import '../../../scss/utilities/variables';
@import '../../../scss/utilities/mixins/breakpoints';

.who-we-are {
  h1 {
    text-align: center;
    padding: 40px 0;
  }
  &__acumen {
    display: flex;
  }
  &__rockefeller {
    display: flex;
    flex-direction: row-reverse;
  }
  &__image {
    flex: 1;
  }
  &__text {
    flex: 1;
    font-size: 1.2em;
    padding: 5em;
  }
}

@include breakpoint-max(s) {
  .who-we-are{
    &__acumen {
      flex-direction: column;
    }
    &__rockefeller {
      flex-direction: column;
    }
    &__image {
      img {
        width: 100%;
      }
    }
    &__text {
      padding: 1em;
    }
  }
}
