@import '../../../scss/utilities/variables';
@import '../../../scss/utilities/mixins/breakpoints';

.call-to-action-section {
    background-color: $violet;
    text-align: center;
    color: $white;
    padding: 40px 0 40px 0;
    h2 {
        font-size: 2.6em;
        padding-bottom: 0.4em;
        font-family: 'ITC Avant Garde'
    }
    p {
        font-size: 1.35em;
        margin: 0 12em 0 13em;
        padding-bottom: 2em;
    }
}

@include breakpoint-max(s) {
    .call-to-action-section {
        p {
            margin: 0;
        }
    }
}
