@import '../../../../../scss/utilities/variables';
@import '../../../../../scss/utilities/mixins/breakpoints';

.subscription  {
  background-color: $violet;
  color: $white;
  padding: 40px 0 40px 0;
  input {
    padding: 17px;
    margin-right: 1em;
    outline: none;
    border-radius: 4px;
    width: 250px;
  }
  h3 {
    font-size: 1.5em;
  }
  &__right {
    padding-top: 40px;
  }
  &__thanks {
    display: inline;
  }
}

@include breakpoint(xxs) {
  .subscription {
    input {
      width: 100%;
      margin: 0;
      margin-bottom: 2em;
    }
    .btn {
      width: 100%;
    }
  }
}