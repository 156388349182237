@import '../../../../../scss/utilities/variables';
@import '../../../../../scss/utilities/mixins/breakpoints';

.competition-intro {
  text-align: center;
  padding: 60px 0;
  &__header {
    margin: 0 9em;
    h1 {
      font-size: 3em;
    }
  }
  &__organization {
    background-color: $violet;
    color: $white;
    padding: 2em;
    border-radius: 2px;
  }
  &__text {
    font-size: 1.8em;
    padding-bottom: 60px;
  }
}

@include breakpoint-max(s) {
  .competition-intro {
    &__header {
      margin: 0;
    }
    &__logo {
      img {
        width: 80%;
      }
    }
    &__organization {
      margin-bottom: 2em;
    }
  }
}
