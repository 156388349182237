@import "../../../../../scss/utilities/variables";
@import "../../../../../scss/utilities/mixins/breakpoints";

.sector-experts {
    padding: 60px 0;
    img {
        height: 100px;
        border-radius: 50%;
    }
    &__intro {
        margin: 2em 14em;
        text-align: center;
        padding-bottom: 2em;
    }
    .col-m-4 {
        position: relative;
        .inner-tile-box {
            position: absolute;
            border-right: 1px solid gainsboro;
            height: 70%;
            right: 0;
            top: 10%;
        }
        hr {
            position: absolute;
            bottom: 0;
            left: 40px;
            width: 80%;
            border-color: gainsboro;
        }
    }
    &__tile {
        padding-bottom: 2em;
        height: 100%;
        width: 100%;
        position: relative;
        .inner-box {
            // border-right: 1px solid gainsboro;
            position: absolute;
            right: 0;
            top: 10%;
        }
        .no-border {
            border-right: 0;
        }
        h3 {
            margin-bottom: 0;
            padding-top: 0.8em;
        }
        p {
            margin-top: 0;
        }
    }
}

@include breakpoint-max(s) {
    .sector-experts {
        &__intro {
            margin: 0;
        }
        &__tile {
            text-align: center;
            .inner-box {
                position: relative;
                border: none;
            }
        }
        hr {
            display: none;
        }
    }
}
