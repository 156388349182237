@import '../../../scss/utilities/variables';
@import '../../../scss/utilities/mixins/breakpoints';

.thank-you-page {
  padding: 170px 0;
  h1 {
    text-transform: capitalize;
  }
  p {
    font-size: 20px;
    padding-bottom: 2em;
  }
  ul {
    list-style-position: inside;
    list-style-type: decimal;
    li {
      margin-bottom: 1em;
    }
  }
}

@include breakpoint-max(s) {
  .thank-you-page {
    padding: 50px;
    img {
      height: 50px;
    }
  }
}