@import '../../../../../scss/utilities/variables';
@import '../../../../../scss/utilities/mixins/breakpoints';

.additional-resources {
  padding-top: 60px;
  .card {
    margin-bottom: 25px;
    height: 100%;
  }
  a {
    padding: 1em;
  }
  .card__text {
    min-height: 0pt;
  }
  .card__title {
    color: $black;
  }
  &__featured {
    position: relative;
    img {
      max-width: 100%;
      height: 400px;
      object-fit: cover;
    }
    h2 {
      margin: 1em;
    }
  }
}

.featured-text {
  position: absolute;
  color: $white;
  font-family: 'ITC Avant Garde';
  font-weight: bold;
  font-size: 20px;
  z-index: 3;
  &.top {
    top: 25px;
    left: 35px;
  }
  &.bottom {
    bottom: 30px;
    left: 16px;
    p {
      font-size: 16px;
      font-weight: normal;
      font-family: 'Caecelia LT Std';
    }
  }
}

@include breakpoint-max(s) {
  .featured-text {
    &.top {
      left: 35px;
    }
    &.bottom {
      left: 14px;
    }
  }
  .additional-resources {
    &__featured {
      h2 {
        font-size: 24px;
        margin: 1em;
      }
      img {
        margin-bottom: 20px;
      }
    }
    .card {
      margin: 0;
      margin-bottom: 25px;
    }
  }
}