//==
// Grid System - Columns
//##
@mixin grid-columns($breakpoint) {
    // Calculate column width and without gutter.
    $column-width: (100% / $grid-column-count);
    $max-columns: $grid-column-count + 1;

    // Loop through and build the columns.
    @for $i from 1 to ($max-columns) {
        $temp-width: $column-width * $i;

        // 1: Firefox will break when content overflows without this.
        .col-#{$breakpoint}-#{$i} {
            max-width: $temp-width; // 1
            flex: 0 0 $temp-width;
        }
        // We don't want add offsets etc to full width columns.
        @if $i < $max-columns {         
            
            // Positional resets happen outside the loop
            .offset-#{$breakpoint}-#{$i} {
                margin-left: $temp-width;

                [dir="rtl"] & {
                    margin-right: $temp-width;
                }
            }

            .push-#{$breakpoint}-#{$i} {
                left: $temp-width;

                [dir="rtl"] & {
                    right: $temp-width;
                }
            }

            .pull-#{$breakpoint}-#{$i} {
                right: $temp-width;

                [dir="rtl"] & {
                    left: $temp-width;
                }
            }
        }
    }
}

//==
// Grid System - Block Grid Columns
//##
@mixin block-grid-columns($breakpoint) {
    $max-columns: $grid-column-count + 1;
    // Loop through and set width.
    @for $i from 1 to ($max-columns) {
        
        // Calculate column width .
        $column-width: (100% / $i);

        .block-row-#{$breakpoint}-#{$i} {

            > * {
                flex: 0 0 $column-width;
            }
        }
    }
}
