// 1. Use the iOS devices hardware accelerator to provide native scrolling.
html {
    -webkit-overflow-scrolling: touch; // 1
    color: $global-font-color;
    font-size: $global-font-size;
    box-sizing: border-box;
}

// Fix the box model
*, :before, :after {
    box-sizing: inherit;
}

// Suppress the focus outline on links that cannot be accessed via keyboard.
// This prevents an unwanted focus outline from appearing around elements that
// might still respond to pointer events.
[tabindex="-1"]:focus {
    outline: none;
}

// Headers
// 1: Improve rendering of screen fonts.
// 2: Prevent page breaking.
h1, h2, h3, h4, h5, h6 {
    text-rendering: optimizeLegibility; // 1
    page-break-after: avoid; // 2
}

// Address variable `h1` font-size and margin within `section` and `article`
// contexts in Firefox 4+, Safari, and Chrome.
h1 {
    font-size: $h1-font-size;
    margin: $h1-margin 0;
}

h2 {
    font-size: $h2-font-size;
    margin: $h2-margin 0;
}

h3 {
    font-size: $h3-font-size;
    margin: $h3-margin 0;
}

h4 {
    font-size: $h4-font-size;
    margin: $h4-margin 0;
}

h5 {
    font-size: $h5-font-size;
    margin: $h5-margin 0;
}

h6 {
    font-size: $h6-font-size;
    margin: $h6-margin 0;
}

// A better looking default horizontal rule
hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $horizontal-rule-color;
    margin: 1rem 0;
    padding: 0;
}

ul, 
li {
    margin: 0;
    padding: 0;
}
ul > li {
    margin-left: 20px;
}

a {
  text-decoration: none;
}
