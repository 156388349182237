.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  &__main {
    position: fixed;
    background: white;
    width: 50%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 3em;
    border-radius: 3px;
    img {
      height: 30px;
    }
  }
  &__img {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 20px !important;
    cursor: pointer;
  }
}
