// 1. Global
// -----------------------------------------------------------------------------

$global-font-size:          16px;                // Mobile
$global-font-size-small:    $global-font-size;   // Tablet & up
$global-font-size-medium:   $global-font-size;   // Desktop & up
$global-font-size-large:    $global-font-size;   // Large screens & up

$global-font-color: #222 !default;
$global-border-color: #c0c0c0 !default;
$global-input-border-color: #c0c0c0 !default;
$global-disabled-color: #ebebeb !default;

$global-lineheight:         1.3;                 // Mobile
$global-lineheight-small:   $global-lineheight;  // Tablet & up
$global-lineheight-medium:  $global-lineheight;  // Desktop & up
$global-lineheight-large:   $global-lineheight;  // Large screens & up

$global-width:              320px;               // Mobile
$global-width-small:        728px;               // Tablet & up
$global-width-medium:       1200px;              // Desktop & up
$global-width-large:        1400px;              // Large screens & up

$global-margin:             30px;
$global-padding:            30px;

$global-weight-normal:      400;
$global-weight-medium:      500;
$global-weight-semibold:    600;
$global-weight-bold:        700;

$global-outline:            none;
$global-radius:             3px;
$global-text-direction:     ltr;
$global-transition-timing:  .2s;
$global-box-sizing:         border-box;

$horizontal-rule-color:     $global-border-color                          !default;

$black:                    #000;
$white:                    #fff;

$xlight-gray:              #f0f0f0;
$light-gray:               #f4f4f4;
$gray:                     #dcdcdc;
$medium-gray:              #b4b4b4;
$dark-gray:                #686868;
$xdark-gray:               #231e20;

$acumen-blue:              #0093bb;
$red:                      #da001a;
$pink:                     #e52d87;
$orange:                   #ec7404;
$lime:                     #5d8e1c;
$magenta:                  #b5007c;

$tangerine:                #e75113;
$green:                    #087323;
$azure:                    #006f84;
$violet:                   #6d236f;
$navy:                     #002f71;

$body-background:          $white;
$body-font-color:          $dark-gray;
$body-font-family:         -apple-system, BlinkMacSystemFont,
 "Trebuchet MS", Helvetica, sans-serif;

$header-1-size:            rem-calc($global-font-size + 35)               !default;
$header-2-size:            rem-calc($global-font-size + 28)               !default;
$header-3-size:            rem-calc($global-font-size + 21)               !default;
$header-4-size:            rem-calc($global-font-size + 14)               !default;
$header-5-size:            rem-calc($global-font-size + 7)                !default;
$header-6-size:            rem-calc($global-font-size)                    !default;

$xxxlarge-font-size:       $header-1-size                                 !default;
$xxlarge-font-size:        $header-2-size                                 !default;
$xlarge-font-size:         $header-3-size                                 !default;
$large-font-size:          $header-4-size                                 !default;
$medium-font-size:         $header-5-size                                 !default;
$normal-font-size:         $header-6-size                                 !default;
$small-font-size:          rem-calc($global-font-size - 1)                !default;
$xsmall-font-size:         rem-calc($global-font-size - 3)                !default;
$xxsmall-font-size:        rem-calc($global-font-size - 5)                !default;
$xxxsmall-font-size:       rem-calc($global-font-size - 7)                !default;

$h1-font-size:             4rem                                           !default;
$h1-margin:                1.34rem                                        !default;

$h2-font-size:             1.5rem                                         !default;
$h2-margin:                1.245rem                                       !default;

$h3-font-size:             1.17rem                                        !default;
$h3-margin:                1.17rem                                        !default;

$h4-font-size:             1rem                                           !default;
$h4-margin:                1.33rem                                        !default;

$h5-font-size:             0.83rem                                        !default;
$h5-margin:                1.386rem                                       !default;

$h6-font-size:             0.67rem                                        !default;
$h6-margin:                1.561rem                                       !default;


// 2. Breakpoints
// ----------------------------------------------------------------------------------


$grid-container-max-width:      1140px                                    !default;
$grid-column-gutter:            50px                                      !default;
$grid-column-count:             12                                        !default;

// Breakpoints. These have been carefully considered so it
// is recommended to leave them as they are.
$grid-breakpoint-xxs-max:       37.49em                                   !default;
$grid-breakpoint-xs-min:        37.5em                                    !default;
$grid-breakpoint-xs-max:        47.99em                                   !default;
$grid-breakpoint-s-min:         48em                                      !default;
$grid-breakpoint-s-max:         61.99em                                   !default;
$grid-breakpoint-m-min:         62em                                      !default;
$grid-breakpoint-m-max:         74.99em                                   !default;
$grid-breakpoint-l-min:         75em                                      !default;

// Print Breakpoints. Printing on A4 only maintains
// xs grid layout so we shift the xs/s breakpoint to force it to print using the
// s grid. These are deliberately set at pixel values to ensure
// that font-size doesn't affect output.
$grid-print-breakpoint-xs-max:   536px                                     !default;
$grid-print-breakpoint-s-min:    537px                                     !default;
