// Clearfix: contain floats
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//	  `contenteditable` attribute is included anywhere else in the document.
//	  Otherwise it causes space to appear at the top and bottom of elements
//	  that receive the `clearfix` class.
// 2. The use of `table` rather than `block` is only necessary if using
//	  `:before` to contain the top-margins of child elements.
.clearfix {
    &:before,
    &:after {
        content: ""; // 1
        display: table; // 2
    }

    &:after {
        clear: both;
    }
}

// Float helpers
.push {
    float: right;

    [dir="rtl"] & {
        float: left;
    }
}

.pull {
    float: left;

    [dir="rtl"] & {
        float: right;
    }
}
// XXS, XS, S, M, L viewports.
@include push-pull(xxs);
@include push-pull(xs);
@include push-pull(s);
@include push-pull(m);
@include push-pull(l);

// Layout helpers
.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.inline {
    display: inline;
}

// XXS, XS, S, M, L viewports.
@include layout(xxs);
@include layout(xs);
@include layout(s);
@include layout(m);
@include layout(l);

// Text align helpers
.text-pull {
    text-align: left;

    [dir="rtl"] & {
        text-align: right;
    }
}

.text-push {
    text-align: right;

    [dir="rtl"] & {
        text-align: left;
    }
}

.text-center {
    text-align: center;
}

// XXS, XS, S, M, L viewports.
@include text-align(xxs);
@include text-align(xs);
@include text-align(s);
@include text-align(m);
@include text-align(l);

// Grid state detection.
.gsi {
    position: absolute;
    top: -9999px;
    height: 1px;
    width: 0;
}

// Breakpoint driven changes. 0 based so we can parse easily with JavaScript.
@include grid-state(xxs, 0);
@include grid-state(xs, 1px);
@include grid-state(s, 2px);
@include grid-state(m, 3px);
@include grid-state(l, 4px);

// Scrollbar width measurement helper
.scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

// 1: Locks the height of the document removing the scrollbar.
[data-lock] {
    &,
    & body {
        // height: 100%;
        overflow: hidden;
        touch-action: none;
    }
}
