@import '../../../scss/utilities/variables';
@import '../../../scss/utilities/mixins/breakpoints';

.get-started-intro {
  padding: 60px 0;
  min-height: 90vh;
  h1 {
    font-size: 3em;
    margin-bottom: 0;
  }
  p {
    font-size: 1.3em;
    margin-top: .5em;
  }
  &__container {
    max-width: 65%;
    margin: 0 auto;
  }
}

@include breakpoint-max(s) {
  .get-started-intro {
    &__container {
      max-width: 100%;
    }
  }
}
