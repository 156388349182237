@import '../../../../../scss/utilities/variables';
@import '../../../../../scss/utilities/mixins/breakpoints';

.challenge-intro {
  padding-bottom: 60px;
  p {
    margin-right: 2em;
    font-size: 20px;
  }
  img {
    height: 300px;
    float: right;
    object-fit: contain;
  }
}

@include breakpoint-max(s) {
  .challenge-intro {
    img {
      width: 100%;
    }
  }
}