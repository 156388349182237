@import '../../../../scss/utilities/variables';

.accordion-section {
  border-bottom: 1px solid $medium-gray;
  padding: 1em;
}

a {
  color: $magenta;
}
.alert-enter {
  opacity: 0;
}
.alert-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}


.accordion {
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  color: $acumen-blue;
  &::after {
    content: '\02795';
    font-size: 13px;
    color: red;
    float: right;
    margin-left: 5px;
  }
  &.active:after {
    content: "\2796"; /* Unicode character for "minus" sign (-) */
  }
}
