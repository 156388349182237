.competition {
  padding: 60px 0;
  h2 {
    font-size: 3em;
    text-align: center;
    padding-bottom: 60px;
  }
  h4 {
    margin-bottom: 2px;
  }
  img {
    width: 100%;
  }
  ul {
    li {
      list-style: disc;
    }
  }
  button {
    margin-top: 40px;
  }
}
